.about-skills-div{
  width: 100vw;
 background: rgba(76, 94, 154);
}

.description-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 30px;


}

.about-me {
  font-size: 40px;
  text-transform: uppercase;
  color:#f7f7f7f7;
}

.about-me:hover {
  transform: rotate(7deg);
  box-shadow: 4px 2px 3px #f7f7f7f7;

}
.brand-statement-div{
  box-shadow: 1px 2px 2px 3px #c2c0c0f7;
  padding: 20px;
}

.brand-statement {
  width: 80vw;
  font-size: 23px;
  line-height: 2rem;
  color: #f7f7f7f7;


}



.skills-main-div {
 display: flex;
justify-content: center;
  align-items: center;
  flex-direction: column;
 height: 100vh;
  width: 100vw;
  
}

.skills-div {
 
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 25px;
  margin: auto;
  /* text-align: center; */
  border-radius:8px;
  box-shadow: 8px 9px 10px 5px gray;
  width: 50vw;
  padding: 40px;
  background-color: #f7f7f7f7;;
}

.icon-title-div{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  text-align: center;
}
.icon-title{

font-size: 40px;
margin: 0;

/* padding-bottom: 50px; */
text-transform: uppercase;
font-weight: 900;
color: #f7f7f7f7;;
}

.icon-title-div:hover {
  transform: rotate(7deg);
  
  
  
}

.tech-icon {
  font-size: 9vw;
  color: rgb(21, 44, 21);
 
}
.icon-div {
  transition: transform .8s, filter 2s ease-out;
}

.icon-div:hover {
  transform: scale(1.3);

}

