.contact-container {
  background: #f7f7f7f7;
}
.text-div {
  margin-top: 100px;
 
}

.contact-text {
  text-align: center;
  color:rgba(76, 94, 154);
}

.contact-text:hover {
  transform: rotate(8deg);
}

.contact-div {
  display: flex;
align-items: center;
flex-direction: column;

width: 50vw;
height: 50vh;
padding: 20px;
margin: auto;
border-radius: 20px;
margin-top: 40px;

}


.contact-input {
  background: #ffffff;
  opacity: .7;
  outline: none;
  
  border:2px solid black;
  width: 45vw;
  height: 40px;
  margin: 15px;
  padding-left: 20px;
  border-radius: 10px;
  
}
textarea{
  resize: none;
  height: 150px;
  background: #ffffff;
  opacity: .7;
  outline: none;
  padding-left: 20px;
  border:2px solid black;
  width: 45vw;
  /* padding-left: 10px; */
  margin: 15px;
  border-radius: 10px;
}

textarea:hover{
  border: 4px solid green;
}
.contact-input:hover,
 .contact-input:active {
  border: 4px solid green;
}


textarea::placeholder {
  font-family: 'Lato', sans-serif;
}


.submit {
  display: flex ;
  justify-content: center ;
    align-items: center ;
    margin: auto ;
    width: 40vw ;
    height: 30px ;
    outline: none ;
    cursor: pointer ;
    border: none ;
    border-radius: 8px ;
    color: rgba(76, 94, 154);
    font-size: 20px;
    font-weight: 800;
    

}

.submit:hover{
  border: 6px solid rgb(13, 120, 243);
  color: black;
  opacity: .7;


}
.contact-links {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.contact-github,
 .contact-in, 
 .contact-resume {
  padding:40px;
  
  transition: transform .8s, filter 1s ease-out;
}

.in, 
.git
 {
  padding: 30px;
  font-size: 80px;
  color: black;
  
}
.resume {
  font-size: 81px !important;
  
  color: black;
}


.contact-github:hover{
  transform: scale(1.3);
  transform: rotate(-20deg);
}

.contact-in:hover {
  transform: scale(1.3);
  transform: rotate(20deg);
}

.contact-resume:hover {
  transform: scale(1.3);
  
}
