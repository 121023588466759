body {
  box-sizing: border-box;
  
} 


.projects-container_div {
  display: flex;
  flex-direction: row-reverse;
  width: 100vw;
  height: 70vh; 
  padding-left: 40px;
  padding-top: 60px;
  /* background-color: rgba(143, 144, 144, 0.8); */
  background: rgba(30, 30, 47, 0.9);
  /* background-image:      linear-gradient(to right, #64495E, #4C5F9A); */
  /* background: rgba(76, 94, 154); */
 
  margin-top: -21px;
  
}



.name-desciption_div {
  display: flex;
  width: 50vw;
  padding: 40px;
  padding-right: 30px;
 
 flex-direction: column;
 

}

.project-description {
  line-height: 1.7rem;
 font-size: 20px;
 font-weight: 500;
 color: #f7f7f7f7;

  
 
}

.project-name {
  font-family: 'Audiowide', cursive;
  font-weight: 900;
  color: #f7f7f7f7;
  font-size:40px ;
}

.build-with-div {
  /* padding-top: 200px; */
  width: 35vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 120px;
  padding-right: 20px;
 }



.project-icons-div {
  padding-top: 50px;
  display: grid;
  grid-template-columns: 25% 25% 25% ;
  grid-gap: 15px;
  margin-bottom: 0px;
  
 
}



.project-icon {
 font-size: 50px;
 padding-left: 10px;
 
}
.project-button{
  transition: transform .8s, filter 2s ease-out;
}

.project-button:hover {
  transform: scale(1.3);
  transform: rotate(15deg);
  
}


.project-buttons-div {
 
 
 padding-top: 100px;
 display: grid;
 grid-template-columns: 55% 50%  ;
 grid-gap: 25px;
 transition: transform .8s, filter 2s ease-out;
 
  
}





.github, .live {
  
  
  font-size: 60px !important;
  margin: auto;
  color:#f7f7f7f7;
 
 
  
  
}
a {
  text-decoration: none;
}

.live {
  font-size: 60px !important;
  color: #f7f7f7f7;
  margin-left: 15px;
}



.liveP{
  font-family: 'Monoton', cursive;
  padding-left: 25px;
  padding-bottom: 5px;
  font-weight: 800;
  color:  #f7f7f7f7;
}

.githubP{
  font-family: 'Monoton', cursive;
  padding-left: 0px;
  padding-top: 3px;
  font-weight: 800;
  color: #f7f7f7f7;
}

.website-image_div {
  border-radius: 8px;
  transition: transform .8s, filter 1s ease-out;
  padding: 20px;
  padding-left: 40px;
  
}

.website-image_div:hover {
transform: scale(1.1);

}
.website-image {
  height: 50vh;
  width: 50vw;
  border-radius: 8px;

  box-shadow: 2px 3px 2px  #f7f7f7f7;
}


@media screen and (max-width: 600px) {
  .projects-container_div {
    height:109vh;
    display: block;
    padding-top: 0px;
    


  }

  .name-desciption_div{
    width: 100vw;
    margin: auto;
   padding-left: 0;
   padding-top: 0;
  }

  .project-description {
    line-height: 1rem;
   font-size: 16px;
   width: 80vw;
   padding-top: 0px;
   
  }

  .project-name {
    
    font-size:  28px;
    padding-top: 15px;
    padding-bottom: 0;
  }

 

   .website-image_div {
    
   width: 100vw;
   padding-left: 0;
   height: 300px;
   margin: auto;
   padding-top: 0px;

    
  }

  .website-image {
    width: 80vw;
    height: 300px;
   margin: auto;
   padding-left: 0;
  }

  .build-with-div {
    /* text-align: center; */
    width: 80vw;
   
    margin: auto;
    padding-bottom: 0;
   margin-bottom: 2px;
    padding-top: 120px;

  }

  .project-buttons-div {
    width: 50vw;
    height: 300px;
    padding-top: 25px;
    margin: auto;
  }
  
  .github {
    font-size: 35px !important;
  }

  .live{
    font-size: 35px !important;
  }
}